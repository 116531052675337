<template>
  <div class="form-components-showcase">
    <div class="header">
      <router-link :to="{ name: 'developer-playground' }" class="back-button">
        <v-icon>mdi-arrow-left</v-icon>
        Back to Playground
      </router-link>
      <h1>Form Components</h1>
      <p class="subtitle">Explore and test form input components and styles</p>
      <router-link :to="{ name: 'form-styles' }" class="styles-button">
        <v-icon>mdi-palette</v-icon>
        View Form Styles
      </router-link>
    </div>

    <div class="sections">
      <!-- Text Inputs Section -->
      <div class="section">
        <h2>Text Inputs</h2>
        <div class="component-grid">
          <div class="component-card">
            <h3>Standard Input</h3>
            <div class="component-demo">
              <v-text-field
                v-model="textInputs.standard"
                label="Standard"
                placeholder="Enter text"
              ></v-text-field>
            </div>
          </div>

          <div class="component-card">
            <h3>Required Input</h3>
            <div class="component-demo">
              <v-text-field
                v-model="textInputs.required"
                label="Required"
                placeholder="Required field"
                :rules="[v => !!v || 'Field is required']"
                required
              ></v-text-field>
            </div>
          </div>

          <div class="component-card">
            <h3>With Icon</h3>
            <div class="component-demo">
              <v-text-field
                v-model="textInputs.withIcon"
                label="With Icon"
                placeholder="Search..."
                prepend-icon="mdi-magnify"
              ></v-text-field>
            </div>
          </div>

          <div class="component-card">
            <h3>Password Input</h3>
            <div class="component-demo">
              <v-text-field
                v-model="textInputs.password"
                :type="showPassword ? 'text' : 'password'"
                label="Password"
                placeholder="Enter password"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPassword = !showPassword"
              ></v-text-field>
            </div>
          </div>
        </div>
      </div>

      <!-- Selection Inputs Section -->
      <div class="section">
        <h2>Selection Inputs</h2>
        <div class="component-grid">
          <div class="component-card">
            <h3>Checkbox</h3>
            <div class="component-demo">
              <v-checkbox
                v-model="selectionInputs.checkbox"
                label="Checkbox"
              ></v-checkbox>
            </div>
          </div>

          <div class="component-card">
            <h3>Radio Group</h3>
            <div class="component-demo">
              <v-radio-group v-model="selectionInputs.radio" row>
                <v-radio label="Option 1" value="1"></v-radio>
                <v-radio label="Option 2" value="2"></v-radio>
              </v-radio-group>
            </div>
          </div>

          <div class="component-card">
            <h3>Switch</h3>
            <div class="component-demo">
              <v-switch
                v-model="selectionInputs.switch"
                label="Switch"
              ></v-switch>
            </div>
          </div>

          <div class="component-card">
            <h3>Select</h3>
            <div class="component-demo">
              <v-select
                v-model="selectionInputs.select"
                :items="selectItems"
                label="Select"
                placeholder="Choose an option"
              ></v-select>
            </div>
          </div>
        </div>
      </div>

      <!-- Special Inputs Section -->
      <div class="section">
        <h2>Special Inputs</h2>
        <div class="component-grid">
          <div class="component-card">
            <h3>Date Picker</h3>
            <div class="component-demo">
              <v-menu
                v-model="specialInputs.dateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="specialInputs.date"
                    label="Date"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    prepend-icon="mdi-calendar"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="specialInputs.date"
                  no-title
                  @input="specialInputs.dateMenu = false"
                ></v-date-picker>
              </v-menu>
            </div>
          </div>

          <div class="component-card">
            <h3>Slider</h3>
            <div class="component-demo">
              <v-slider
                v-model="specialInputs.slider"
                label="Slider"
                thumb-label="always"
              ></v-slider>
            </div>
          </div>

          <div class="component-card">
            <h3>File Input</h3>
            <div class="component-demo">
              <v-file-input
                v-model="specialInputs.file"
                label="File input"
                placeholder="Select a file"
                prepend-icon="mdi-paperclip"
              ></v-file-input>
            </div>
          </div>

          <div class="component-card">
            <h3>Textarea</h3>
            <div class="component-demo">
              <v-textarea
                v-model="specialInputs.textarea"
                label="Textarea"
                placeholder="Enter multiple lines of text"
                rows="3"
              ></v-textarea>
            </div>
          </div>
        </div>
      </div>

      <!-- Upload Release Form Section -->
      <div class="section">
        <h2>Upload Release Form</h2>
        <div class="component-grid">
          <div class="component-card">
            <h3>Release Title</h3>
            <div class="component-demo">
              <v-text-field
                v-model="uploadForm.title"
                label="Release Title"
                placeholder="Enter release title"
                :rules="[v => !!v || 'Title is required', v => v.length <= 50 || 'Title must be less than 50 characters']"
                counter="50"
                required
              ></v-text-field>
            </div>
          </div>

          <div class="component-card">
            <h3>Description</h3>
            <div class="component-demo">
              <v-textarea
                v-model="uploadForm.description"
                label="Description"
                placeholder="Enter release description"
                :rules="[v => !v || v.length <= 2000 || 'Description must be less than 2000 characters']"
                counter="2000"
                rows="3"
              ></v-textarea>
            </div>
          </div>

          <div class="component-card">
            <h3>Genre Selection</h3>
            <div class="component-demo">
              <v-select
                v-model="uploadForm.genre"
                :items="genres"
                label="Genre"
                item-text="name"
                item-value="id"
                required
                :rules="[v => !!v || 'Genre is required']"
              ></v-select>
            </div>
          </div>

          <div class="component-card">
            <h3>Subgenre Selection</h3>
            <div class="component-demo">
              <v-select
                v-model="uploadForm.subgenre"
                :items="subgenres"
                label="Subgenre"
                item-text="name"
                item-value="id"
                required
                :rules="[v => !!v || 'Subgenre is required']"
              ></v-select>
            </div>
          </div>

          <div class="component-card">
            <h3>Release Date</h3>
            <div class="component-demo">
              <v-menu
                v-model="uploadForm.dateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="uploadForm.releaseDate"
                    label="Release Date"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    required
                    :rules="[v => !!v || 'Release date is required']"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="uploadForm.releaseDate"
                  no-title
                  @input="uploadForm.dateMenu = false"
                ></v-date-picker>
              </v-menu>
            </div>
          </div>

          <div class="component-card">
            <h3>Tags Input</h3>
            <div class="component-demo">
              <v-combobox
                v-model="uploadForm.tags"
                label="Tags"
                multiple
                chips
                small-chips
                deletable-chips
                :rules="[v => v.length > 0 || 'At least one tag is required']"
              ></v-combobox>
            </div>
          </div>

          <div class="component-card">
            <h3>Artist Selection</h3>
            <div class="component-demo">
              <v-select
                v-model="uploadForm.artist"
                :items="artists"
                label="Artist"
                item-text="name"
                item-value="id"
                required
                :rules="[v => !!v || 'Artist is required']"
              ></v-select>
            </div>
          </div>

          <div class="component-card">
            <h3>Label Selection</h3>
            <div class="component-demo">
              <v-select
                v-model="uploadForm.label"
                :items="labels"
                label="Label"
                item-text="title"
                item-value="id"
              ></v-select>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FormComponentsShowcase',
  data: () => ({
    showPassword: false,
    textInputs: {
      standard: '',
      required: '',
      withIcon: '',
      password: '',
    },
    selectionInputs: {
      checkbox: false,
      radio: '1',
      switch: false,
      select: null,
    },
    specialInputs: {
      date: null,
      dateMenu: false,
      slider: 50,
      file: null,
      textarea: '',
    },
    selectItems: [
      { text: 'Option 1', value: 1 },
      { text: 'Option 2', value: 2 },
      { text: 'Option 3', value: 3 },
    ],
    uploadForm: {
      title: '',
      description: '',
      genre: null,
      subgenre: null,
      releaseDate: null,
      dateMenu: false,
      tags: [],
      artist: null,
      label: null,
    },
    genres: [
      { id: 2, name: 'Psychedelic Trance' },
      { id: 3, name: 'Progressive Trance' },
      { id: 4, name: 'Full-On' },
    ],
    subgenres: [
      { id: 1, name: 'Dark Psy' },
      { id: 2, name: 'Forest Psy' },
      { id: 3, name: 'Hi-Tech' },
    ],
    artists: [
      { id: 1, name: 'Artist 1' },
      { id: 2, name: 'Artist 2' },
      { id: 3, name: 'Artist 3' },
    ],
    labels: [
      { id: 1, title: 'Label 1' },
      { id: 2, title: 'Label 2' },
      { id: 3, title: 'Label 3' },
    ],
  }),
}
</script>

<style lang="scss" scoped>
.form-components-showcase {
  padding: 2rem;
  max-width: 1400px;
  margin: 0 auto;

  .header {
    margin-bottom: 3rem;
    text-align: center;
    position: relative;

    .back-button {
      position: absolute;
      left: 0;
      top: 0;
      display: flex;
      align-items: center;
      text-decoration: none;
      color: #20C4F5;
      font-size: 1.1rem;
      transition: color 0.3s ease;

      &:hover {
        color: lighten(#20C4F5, 10%);
      }

      .v-icon {
        margin-right: 0.5rem;
      }
    }

    h1 {
      color: #fff;
      font-size: 2.5rem;
      margin-bottom: 1rem;
    }

    .subtitle {
      color: rgba(255, 255, 255, 0.7);
      font-size: 1.1rem;
    }

    .styles-button {
      position: absolute;
      right: 0;
      top: 0;
      display: flex;
      align-items: center;
      text-decoration: none;
      color: #20C4F5;
      font-size: 1.1rem;
      transition: color 0.3s ease;

      &:hover {
        color: lighten(#20C4F5, 10%);
      }

      .v-icon {
        margin-left: 0.5rem;
      }
    }
  }

  .section {
    margin-bottom: 3rem;

    h2 {
      color: #fff;
      font-size: 1.5rem;
      margin-bottom: 1.5rem;
      padding-bottom: 0.5rem;
      border-bottom: 1px solid rgba(95, 105, 134, 0.3);
    }

    .component-grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
      gap: 1.5rem;
    }
  }

  .component-card {
    background: #040A18;
    border: 1px solid #282C47;
    border-radius: 12px;
    padding: 1.5rem;

    h3 {
      color: #fff;
      font-size: 1.2rem;
      margin-bottom: 1rem;
    }

    .component-demo {
      padding: 1rem;
      background: rgba(0, 0, 0, 0.2);
      border-radius: 8px;

      .v-text-field,
      .v-textarea,
      .v-select {
        border: 1px solid #282C47;
        border-radius: 5px;
        padding-left: 16px;
        background: #040A18;
        margin-top: 0;
        padding-top: 0;

        &.v-text-field {
          height: 63px;
        }

        &.v-textarea {
          height: auto;
        }

        &.disabled {
          background: rgba(227, 227, 227, .12);
          cursor: default;
          label {
            color: #8591B1;
          }
          .v-input__control .v-input__slot .v-text-field__slot > input {
            cursor: default;
            color: rgba(#828491, .8);
          }
        }

        .v-input__control {
          .v-input__slot {
            background: transparent !important;
            
            &::before,
            &::after {
              display: none;
            }

            input,
            textarea {
              color: #fff;
              
              &::placeholder {
                color: rgba(255, 255, 255, 0.7);
              }
            }
          }
        }

        .v-messages {
          font-size: 12px;
          color: #E94E4E;
          padding-left: 16px;
        }
      }

      .v-select {
        .v-select__selections {
          color: #fff;
        }

        .v-input__append-inner {
          .v-input__icon--append .v-icon {
            color: #5F6986;
          }
        }
      }
    }
  }
}
</style> 